import { isArray, isNumber, isString, toNumber } from 'lodash';
import { POSM_INPUT_TYPE } from '@/utils/enum';

export default class CreatePOSMCategory {
  /**
   * @param {{inputType: number, items: CreatePOSMCategoryItem[]}} payload
   * @param {String} payload.name
   * @param {Number} payload.inputType
   * @param {Array} payload.items
   * @param {Number} payload.id
   * @param {Number} payload.itemsPage
   */
  constructor(payload) {
    this.name =
      payload.name && isString(payload.name) && payload.name.length > 0
        ? payload.name
        : '';
    this.inputType =
      payload.inputType &&
      isNumber(toNumber(payload.inputType)) &&
      !isNaN(toNumber(payload.inputType))
        ? toNumber(payload.inputType)
        : POSM_INPUT_TYPE.IMAGE;
    this.items = payload.items && isArray(payload.items) ? payload.items : [];
    this.id =
      payload.id &&
      isNumber(toNumber(payload.id)) &&
      !isNaN(toNumber(payload.id))
        ? toNumber(payload.id)
        : null;
    this.itemsPage =
      payload.itemsPage &&
      isNumber(toNumber(payload.itemsPage)) &&
      !isNaN(toNumber(payload.itemsPage))
        ? toNumber(payload.itemsPage)
        : 1;
  }
}
