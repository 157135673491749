import { isArray, isString, toNumber } from 'lodash';

export default class CreatePOSMForm {
  /**
   * @param {{interiorCategories: CreatePOSMCategory[], exteriorCategories: CreatePOSMCategory[]}} payload
   * @param {Number} payload.campaignId
   * @param {Array} payload.stores
   * @param {String} payload.name
   * @param {Array} payload.exteriorCategories
   * @param {Array} payload.interiorCategories
   */
  constructor(payload) {
    this.campaignId = payload.campaignId ? toNumber(payload.campaignId) : null;
    this.stores =
      payload.stores && isArray(payload.stores) ? payload.stores : [];
    this.name =
      payload.name && isString(payload.name) && payload.name.length > 0
        ? payload.name
        : '';
    this.exteriorCategories =
      payload.exteriorCategories && isArray(payload.exteriorCategories)
        ? payload.exteriorCategories
        : [];
    this.interiorCategories =
      payload.interiorCategories && isArray(payload.interiorCategories)
        ? payload.interiorCategories
        : [];
  }
}
